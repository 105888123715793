<template>
  <div class="CedantProfile" >
    <div class="container">

      <h1 class="gavc-h1 text-center mb-4">Editer mon profil</h1>

      <b-form v-on:submit.prevent="modifyCedantProfile()">
        <div class="row">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.firstName.$invalid }">
              <label class="gavc-label" for="cedant-edit-profile-firstName-group">
                Prénom
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="cedant-edit-profile-firstName-group"
                maxlength="128"
                @input="$v.firstName.$touch()"
                type="text"
                v-model="firstName"
              >
              <inputErrorValidationMessage
                v-if="$v.firstName.$invalid"
                message="Votre prénom doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.lastName.$invalid }">
              <label class="gavc-label" for="cedant-edit-profile-lastName-group">
                Nom
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="cedant-edit-profile-lastName-group"
                maxlength="128"
                @input="$v.lastName.$touch()"
                type="text"
                v-model="lastName"
              >
              <inputErrorValidationMessage
                v-if="$v.lastName.$invalid"
                message="Votre nom doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.address.$invalid }">
          <label
            class="gavc-label"
            for="cedant-edit-profile-address-group"

          >
              Adresse
              <span class="gavc-required"> * </span>
              <i
                class="gavc-text-martinique gavc-icon gavc-icon-bulb"
                v-b-tooltip.hover.right="{ variant: 'light' }"
                title="Veuillez indiquer votre adresse personnelle (l’adresse de votre domicile).">
              </i>
          </label>
          <input
            class="gavc-input"
            id="cedant-edit-profile-address-group"
            maxlength="250"
            @input="$v.address.$touch()"
            type="text"
            v-model="address"
          >
          <inputErrorValidationMessage
            v-if="$v.address.$invalid"
            message="Votre adresse doit comporter entre 2 et 250 caractères"
          />
        </div>

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.zipCode.$invalid }">
              <label class="gavc-label" for="cedant-edit-profile-zipCode-group">
                Code postal
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="cedant-edit-profile-zipCode-group"
                maxlength="5"
                @input="$v.zipCode.$touch()"
                type="text"
                v-model="zipCode"
              >
              <inputErrorValidationMessage
                v-if="$v.zipCode.$invalid"
                message="Votre code postal doit être composé de 5 chiffres"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.city.$invalid }">
              <label class="gavc-label" for="cedant-edit-profile-city-group">
                Ville
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="cedant-edit-profile-city-group"
                maxlength="128"
                @input="$v.city.$touch()"
                type="text"
                v-model="city"
              >
              <inputErrorValidationMessage
                v-if="$v.city.$invalid"
                message="Votre ville doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.birthdate.$invalid }"
        >
          <label class="gavc-label" for="cedant-edit-profile-birthdate-input">
            Date de naissance
            <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-field gavc-field--icon">
            <input
              @input="$v.birthdate.$touch()"
              class="gavc-input gavc-input--icon gavc-input--calendar"
              id="cedant-edit-profile-birthdate-input"
              type="date"
              v-model="birthdate"
            >
            <i class="gavc-icon gavc-icon-calendar" ></i>
          </div>
          <inputErrorValidationMessage
            v-if="$v.birthdate.$invalid"
            message="Veuillez renseigner votre date de naissance"
          />
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.birthplace.$invalid }"
        >
          <label class="gavc-label" for="cedant-edit-profile-birthplace-group">
              Lieu de naissance
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="cedant-edit-profile-birthplace-group"
            maxlength="250"
            @input="$v.birthplace.$touch()"
            type="text"
            v-model="birthplace"
          >
          <inputErrorValidationMessage
            v-if="$v.birthplace.$invalid"
            message="Veuillez renseigner votre lieu de naissance (entre 2 et 150 caractères)"
          />
        </div>


        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.nationalite.$invalid }"
        >
          <label class="gavc-label" for="cedant-edit-profile-nationalite-group">
              Nationalité
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="cedant-edit-profile-nationalite-group"
            maxlength="250"
            @input="$v.nationalite.$touch()"
            type="text"
            v-model="nationalite"
          >
          <inputErrorValidationMessage
            v-if="$v.nationalite.$invalid"
            message="Veuillez renseigner votre nationalité (entre 2 et 150 caractères)"
          />
        </div>

        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.phoneNumber.$invalid }"
        >
          <label class="gavc-label" for="cedant-edit-profile-phoneNumber-group">
            Téléphone mobile
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="cedant-edit-profile-phoneNumber-group"
            maxlength="14"
            @input="$v.phoneNumber.$touch()"
            type="tel"
            placeholder="06 __ __ __ __"
            v-mask="'XX XX XX XX XX'"
            v-model="maskedPhoneNumber"
          >
          <inputErrorValidationMessage
            v-if="$v.phoneNumber.$invalid"
            message="Votre numéro de téléphone doit être composé de 10 chiffres"
          />
        </div>

        <hr>

        <p class="gavc-label">
          Vous cédez vos parts parce que
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon" v-for="option in cedantPersonalProjectOptions" :key="option.value">
              <input v-model="personalProject"  type="radio" :id="`cedant-edit-profile-${option.value}`" :name="`cedant-edit-profile-${option.value}`" :value="option.value">
              <label :for="`cedant-edit-profile-${option.value}`">
                  <i :class="`gavc-icon gavc-icon-${option.icon}`"></i>
                  {{option.text}}
              </label>
            </div>
          </div>
          <inputErrorValidationMessage
            v-if="$v.personalProject.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>

        <button
          class="float-right gavc-btn mt-4"
          type="submit"
          :disabled="isInvalidCedant">
            Enregistrer
        </button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric} from 'vuelidate/lib/validators'

import { APICedant } from '@/api/APICedant'
import { isDate } from '@/validators/validators'
import { cedantPersonalProjectList } from '@/variables/cedantPersonalProjectList'

const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')


const apiCedantService = new APICedant()

export default {
  name: 'CedantProfile',
  components: {
    inputErrorValidationMessage
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      cedantId: '',
      cedantPersonalProjectOptions: cedantPersonalProjectList,

      address: '',
      birthdate: '',
      birthplace: '',
      city: '',
      firstName: '',
      lastName: '',
      nationalite: '',
      personalProject: '',
      phoneNumber: '',
      zipCode: '',

      maskedPhoneNumber: '',
    }

  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidCedant () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  watch: {
    maskedPhoneNumber (value) {
      this.phoneNumber = value.replace(/ /g,'')
    }
  },
  validations: {
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    birthplace: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    city: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(127)
    },

    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    nationalite: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    personalProject: {
      required
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },

    zipCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      numeric
    },
  },
  created: function() {
    this.getConnectedCedant()
  },
  methods: {

    getConnectedCedant () {
      this.$store.commit('loadFromAPI')
      apiCedantService.getConnectedCedant(this.token)
        .then((result) => {
          this.cedantId = result.data['id']

          this.address = result.data['address']
          this.birthdate = result.data['birthdate']
          this.birthplace = result.data['birthplace']
          this.city = result.data['city']
          this.nationalite = result.data['nationalite']
          this.personalProject = result.data['personal_project']
          this.phoneNumber = result.data['phone_number']
          this.zipCode = result.data['zip_code']
          this.firstName = result.data['user']['first_name']
          this.lastName = result.data['user']['last_name']

          this.maskedPhoneNumber = this.phoneNumber
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('CedantProfile getConnectedCedant API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    modifyCedantProfile () {
      this.$store.commit('loadFromAPI')
      const data = {
        "address": this.address,
        "birthdate": this.birthdate,
        "birthplace": this.birthplace,
        "city": this.city,
        "nationalite": this.nationalite,
        "personal_project": this.personalProject,
        "phone_number": this.phoneNumber,
        "zip_code": this.zipCode,
        "user": {
          'first_name': this.firstName,
          'last_name': this.lastName
        }
      }
      apiCedantService.modifyConnectedCedant(this.token, data)
      .then(() => {
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast('Votre profil a bien été mis à jour', {
          id: 'notification-toast',
          title: 'Profil mis à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('CedantProfile modifyCedantProfile API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },
  }
}
</script>
