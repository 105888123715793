export const cedantPersonalProjectList = [
  {
    "icon": "donnation",
    "text": "Vous cédez vos parts à vos enfants ou ayants droits",
    "value": "FAMILLE"
  },
  {
    "icon": "high-five",
    "text": "Vos salariés reprennent l’activité",
    "value": "SALARIES"
  },
  {
    "icon": "deckchair",
    "text": "Vous partez en retraite​",
    "value": "RETRAITE"
  },
  {
    "icon": "man-turn",
    "text": "Vous souhaitez faire autre chose​​",
    "value": "AUTREVIE"
  },
  {
    "icon": "handshake",
    "text": "Vous cédez à un associé​​",
    "value": "ASSOCIE"
  }
]
